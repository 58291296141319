<template>
  <div class="crud_form_wrapper">
    <!-- Start:: Title -->
    <div class="form_title_wrapper">
      <h4>{{ $t("SIDENAV.Leagues.add") }}</h4>
    </div>
    <!-- End:: Title -->

    <!-- Start:: Single Step Form Content -->
    <div class="single_step_form_content_wrapper">
      <form @submit.prevent="validateFormInputs">
        <div class="row">

          <!-- Start:: Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.nameAr')" v-model.trim="data.nameAr" required />
          <!-- End:: Name Input -->

          <!-- Start:: Name Input -->
          <base-input col="6" type="text" :placeholder="$t('PLACEHOLDERS.nameEn')" v-model.trim="data.nameEn" required />
          <!-- End:: Name Input -->
          <!-- Start:: Sport Input -->
          <base-select-input col="6" :optionsList="sportList" :placeholder="$t('SIDENAV.Sports.name')"
                             v-model="data.sport" />
          <!-- End:: Sport Input -->
          
           <!-- Start:: Sport Input -->
          <base-select-input col="6" :optionsList="leaguesList" :placeholder="$t('SIDENAV.Leagues.league')"
                             v-model="data.api_league_id" />
          <!-- End:: Sport Input -->

          <base-image-upload-input col="6" identifier="image" :preSelectedImage="data.image.path"
                                   :placeholder="$t('PLACEHOLDERS.image')" @selectImage="selectImage" required />
          
          <!-- Start:: Deactivate Switch Input -->
          <!-- <div class="input_wrapper switch_wrapper my-5">
            <v-switch color="green" :label="data.active ? $t('PLACEHOLDERS.active') : $t('PLACEHOLDERS.notActive')"
              v-model="data.active" hide-details></v-switch>
          </div> -->
          <!-- End:: Deactivate Switch Input -->

          <!-- Start:: Submit Button Wrapper -->
          <div class="btn_wrapper">
            <base-button class="mt-2" styleType="primary_btn" :btnText="$t('BUTTONS.save')" :isLoading="isWaitingRequest"
              :disabled="isWaitingRequest" />
          </div>
          <!-- End:: Submit Button Wrapper -->
        </div>
      </form>
    </div>
    <!-- END:: Single Step Form Content -->
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

export default {
  name: "CreateLeagues",
//   computed: {
//     ...mapGetters({
//       getAppLocale: "AppLangModule/getAppLocale",
//     }),
// },
  data() {
    return {
      sportList: [],
      leaguesList: [],
      total:10,
      page:1,
      // Start:: Loader Control Data
      isWaitingRequest: false,
      // End:: Loader Control Data

      // Start:: Data Collection To Send
      data: {
        nameAr: null,
        nameEn: null,
        sport: null,
        api_league_id: null,
        active: true,
        image: {
          path: null,
          file: null,
        },
      },
      // End:: Data Collection To Send
    };
  },

  computed: {
    ...mapGetters({
      getAppLocale: "AppLangModule/getAppLocale",
    }),
  },

  methods: {
    async getSports() {
  try {
    let res = await this.$axios({
      method: "GET",
      url: `sports?page=0&limit=0`,
    });
    this.sportList=res.data.data;
  } catch (error) {
    console.log(error.response.data.message);
  }
},

async getApiLeague() {
      if(this.page<=this.total){
        try {
          let res = await
          this.$axios({
            method: "GET",
            url: `soccer-leagues`,
            params: {
              page: this.page,
            },
          });
          // console.log(res);
          this.leaguesList = this.leaguesList.concat(res.data.data.res.data);
          this.total = res.data.data.res.meta.pages;
          this.page = parseInt(res.data.data.res.meta.page) + 1;
        } catch (error) {
          // console.log(error.response.data.message);
        }
      }
      this.getApiLeague();
  },
//     async searchChange(searchQuery) {
//       // console.log('search league');
//       if(searchQuery) {
//         try {
//           let res = await
//           this.$axios({
//             method: "GET",
//             url: `search-soccer-leagues?q=` + searchQuery,
//           });
//           console.log(res);
//           this.leaguesList = res.data.data.res.data;
//         } catch (error) {
//           console.log(error.response.data.message);
//         }
//       }else{
//         // this.getApiLeague();
//       }
// },

    // Start:: validate Form Inputs
    validateFormInputs() {
      this.isWaitingRequest = true;

      if (!this.data.nameAr) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.nameAr"));
        return;
      } else if (!this.data.nameEn) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.nameEn"));
        return;
      } else if (!this.data.sport) {
        this.isWaitingRequest = false;
        this.$message.error(this.$t("VALIDATION.sport"));
        return;
      }  else {
        this.submitForm();
        return;
      }
    },
    // End:: validate Form Inputs
    selectImage(selectedImage) {
      this.data.image = selectedImage;
    },
    // Start:: Submit Form
    async submitForm() {
      const REQUEST_DATA = new FormData();
      // Start:: Append Request Data
  REQUEST_DATA.append("name[ar]", this.data.nameAr);
  REQUEST_DATA.append("name[en]", this.data.nameEn);
  REQUEST_DATA.append("sport_id", this.data.sport.id);
  REQUEST_DATA.append("api_league_id", this.data.api_league_id.id);
      if (this.data.image.file) {
        REQUEST_DATA.append("image", this.data.image.file);
      }
      // Start:: Append Request Data

      try {
        await this.$axios({
          method: "POST",
          url: `leagues`,
          data: REQUEST_DATA,
        });
        this.isWaitingRequest = false;
        this.$message.success(this.$t("MESSAGES.addedSuccessfully"));
        this.$router.push({ path: "/leagues/all" });
      } catch (error) {
        this.isWaitingRequest = false;
        this.$message.error(error.response.data.message);
      }
    },

    // End:: Submit Form


  },

  created() {
  this.getSports();
  this.getApiLeague();
  },
};
</script>
